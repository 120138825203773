<script lang="ts" setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

interface Props {
  open: boolean
  errors?: string
  withConfirmation?: boolean
  initCaptcha?: boolean
  trackCaptcha?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['openModal', 'otpComplete'])
const isOpen = computed(() => props.open)
const errorVal = ref()

watch(() => props.errors, (newVal) => {
  errorVal.value = newVal
})
</script>

<template>
  <div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" class="relative z-100" :static="true">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" style="backdrop-filter: blur(10px)" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-full max-w-md min-h-md p-6 transform overflow-hidden rounded-md bg-white align-middle shadow-xl transition-all">
                <ContinueOTPForm :track-captcha="props.trackCaptcha" :init-captcha="props.initCaptcha" :errors="errorVal" :with-confirmation="props.withConfirmation" @change-email="emit('openModal', false)" @go-back="emit('openModal', false)" @otp-complete="(code: string) => emit('otpComplete', code)" />
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
